import React from "react";
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    ShowButton,
    List,
    required,
    ResourceProps,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    NumberInput,
    BooleanInput,
    NumberField,
    BooleanField,
    CheckboxGroupInput,
    FunctionField,
} from "react-admin";

const resourceName = "release-locations-admin";
const options = { label: "Release Locations" };
const icon = undefined;

interface ReleaseLocation {
    id: string;
    name: string;
    location: {
        type: string;
        coordinates: [number, number];
    };
    radius: number;
    available: boolean;
    elements?: string[];
    createdAt?: string;
}

const ResourceList: React.FC = (props) => {
    return (
        <List {...props} title="Release Locations">
            <Datagrid>
                <TextField source="name" label="Location Name" />

                <TextField source="location.coordinates[0]" label="Longitude" />
                <TextField source="location.coordinates[1]" label="Latitude" />

                <NumberField source="radius" label="Radius (meters)" />

                <BooleanField source="available" label="Available" />

                <DateField
                    source="createdAt"
                    label="Created At"
                    showTime
                    options={{
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                    }}
                />
                <FunctionField<ReleaseLocation>
                    source="elements"
                    label="Tab Bar Elements"
                    render={(record) =>
                        record.elements && record.elements.length
                            ? record.elements.join(", ")
                            : "—"
                    }
                />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    );
};

const ResourceCreate: React.FC = (props) => {
    const transform = (data: Partial<ReleaseLocation>) => {
        return {
            ...data,
            location: {
                type: "Point",
                coordinates: data.location?.coordinates || [0, 0],
            },
        };
    };
    return (
        <Create {...props} transform={transform}>
            <SimpleForm>
                {/* Название точки */}
                <TextInput
                    source="name"
                    label="Location Name"
                    validate={[required()]}
                />

                {/* Координаты: долгота и широта */}
                <NumberInput
                    source="location.coordinates[0]"
                    label="Longitude"
                    validate={[required()]}
                />
                <NumberInput
                    source="location.coordinates[1]"
                    label="Latitude"
                    validate={[required()]}
                />

                {/* Радиус */}
                <NumberInput
                    source="radius"
                    label="Radius (meters)"
                    validate={[
                        required(),
                        (value) =>
                            value >= 1
                                ? undefined
                                : "Radius must be at least 1",
                    ]}
                />

                {/* Доступность */}
                <BooleanInput
                    source="available"
                    label="Is Available"
                    defaultValue={true}
                />

                <CheckboxGroupInput
                    source="elements"
                    label="TabBar Elements"
                    choices={[
                        { id: "Chat", name: "Chat" },
                        { id: "Encounters", name: "Encounters" },
                        { id: "MyDates", name: "MyDates" },
                        { id: "Places", name: "Places" },
                    ]}
                    validate={[required()]}
                />
            </SimpleForm>
        </Create>
    );
};

const ResourceEdit: React.FC = (props) => {
    const transform = (data: Partial<ReleaseLocation>) => {
        return {
            ...data,
            location: {
                type: "Point",
                coordinates: data.location?.coordinates || [0, 0],
            },
        };
    };

    return (
        <Edit {...props} transform={transform} title="Edit Release Location">
            <SimpleForm>
                <TextInput
                    source="name"
                    label="Location Name"
                    validate={[required()]}
                />

                <NumberInput
                    source="location.coordinates[0]"
                    label="Longitude"
                    validate={[required()]}
                />
                <NumberInput
                    source="location.coordinates[1]"
                    label="Latitude"
                    validate={[required()]}
                />

                <NumberInput
                    source="radius"
                    label="Radius (meters)"
                    validate={[
                        required(),
                        (value) =>
                            value > 0
                                ? undefined
                                : "Radius must be greater than 0",
                    ]}
                />

                <BooleanInput source="available" label="Available" />

                <CheckboxGroupInput
                    source="elements"
                    label="TabBar Elements"
                    choices={[
                        { id: "Chat", name: "Chat" },
                        { id: "Encounters", name: "Encounters" },
                        { id: "MyDates", name: "MyDates" },
                        { id: "Places", name: "Places" },
                    ]}
                />
            </SimpleForm>
        </Edit>
    );
};

const ResourceShow: React.FC = (props) => {
    return (
        <Show {...props} title="Release Location Details">
            <SimpleShowLayout>
                <TextField source="name" label="Location Name" />

                <NumberField
                    source="location.coordinates[0]"
                    label="Longitude"
                />
                <NumberField
                    source="location.coordinates[1]"
                    label="Latitude"
                />

                <NumberField source="radius" label="Radius (meters)" />

                <BooleanField source="available" label="Available" />

                <DateField
                    source="createdAt"
                    label="Created At"
                    showTime
                    options={{
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                    }}
                />
            </SimpleShowLayout>
        </Show>
    );
};

ResourceList.displayName = `${resourceName}List`;
ResourceCreate.displayName = `${resourceName}Create`;
ResourceEdit.displayName = `${resourceName}Edit`;
ResourceShow.displayName = `${resourceName}Show`;

export default {
    list: ResourceList,
    create: ResourceCreate,
    edit: ResourceEdit,
    show: ResourceShow,
    icon,
    options,
} as Omit<ResourceProps, "name">;
