import React from "react";
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    DateField,
    Show,
    SimpleShowLayout,
    ShowButton,
    ResourceProps,
    ReferenceField,
    useRecordContext,
} from "react-admin";

const resourceName = "referal";
const options = { label: "Referals" };

const GenderField = ({ source }: { source: string }) => {
    const record = useRecordContext();
    if (!record) return null;
    console.log(source);

    if (record[source] != null) {
        const text = genderToString(record[source]);
        return <span>{text}</span>;
    }

    return <span>-</span>;
};

const genderToString = (type: String) => {
    switch (type) {
        case "0":
            return "Man";
        case "1":
            return "Woman";
        case "2":
            return "Nonbinary";
        default:
            return "-";
    }
};

const ResourceList: React.FC = (props) => (
    <List {...props} title="Referals">
        <Datagrid>
            <TextField source="inviter" label="Inviter ID" />

            <ReferenceField source="receiver" reference="user" label="Phone">
                <TextField source="phone" emptyText="Deleted" />
            </ReferenceField>

            <ReferenceField
                source="receiver"
                reference="user"
                label="First Name"
            >
                <TextField source="firstName" emptyText="-" />
            </ReferenceField>

            <ReferenceField
                source="receiver"
                reference="user"
                label="Last Name"
            >
                <TextField source="lastName" emptyText="-" />
            </ReferenceField>

            <ReferenceField
                source="receiver"
                reference="user"
                label="Last Name"
            >
                <TextField source="lastName" emptyText="-" />
            </ReferenceField>

            <ReferenceField source="receiver" reference="user" label="Gender">
                <GenderField source="gender" />
            </ReferenceField>

            <ReferenceField source="receiver" reference="user" label="City">
                <TextField source="city" emptyText="-" />
            </ReferenceField>

            <ReferenceField source="receiver" reference="user" label="State">
                <TextField source="state" emptyText="-" />
            </ReferenceField>

            <TextField source="receiver" label="Receiver ID" />
            <TextField source="receiverPhone" label="Receiver Phone" />
            <BooleanField source="receiverAmbassador" label="Is Ambassador" />

            <DateField
                source="createdAt"
                label="Created At"
                showTime
                options={{
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                }}
            />
            <DateField
                source="udatedAt"
                label="Updated At"
                showTime
                options={{
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                }}
            />

            {/* Кнопка просмотра */}
            <ShowButton />
        </Datagrid>
    </List>
);

const ResourceShow: React.FC = (props) => (
    <Show {...props} title="Referral Details">
        <SimpleShowLayout>
            <TextField source="inviter" label="Inviter ID" />
            <TextField source="receiver" label="Receiver ID" />
            <TextField source="receiverPhone" label="Receiver Phone" />
            <BooleanField source="receiverAmbassador" label="Is Ambassador" />

            {/* Читаемые даты */}
            <DateField
                source="createdAt"
                label="Created At"
                showTime
                options={{
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                }}
            />
            <DateField
                source="udatedAt"
                label="Updated At"
                showTime
                options={{
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                }}
            />
        </SimpleShowLayout>
    </Show>
);

ResourceList.displayName = `${resourceName}List`;
ResourceShow.displayName = `${resourceName}Show`;

export default {
    list: ResourceList,
    show: ResourceShow,
    options,
} as Omit<ResourceProps, "name">;
