import * as React from "react";
import {
    fetchUtils,
    Admin,
    Resource,
    Options,
    DataProvider,
    GetManyParams,
    GetManyResult,
} from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import userResource from "./resources/user.resource";
import dateResource from "./resources/date.resource";
import reportResource from "./resources/report.resource";
import paymentResource from "./resources/payments.resource";
import purchaseResource from "./resources/purchases.resource";
import placeResource from "./resources/places.resource";
import invitesResource from "./resources/invites.resource";
import likesResource from "./resources/likes.resource";
import releaseLocationsResource from "./resources/release-locations.resource";
import referalResource from "./resources/referal.resource";

import panelLayout from "./panel-layout";

const httpClient = (url: string, options: Options = {}) => {
    const headers = new Headers({ Accept: "application/json" });

    console.log("Before setting headers:", options.headers); // Добавим этот console.log
    headers.set(
        process.env.REACT_APP_X_SECRET_KEY_HEADER as string,
        process.env.REACT_APP_X_SECRET_KEY as string
    );
    headers.set(
        process.env.REACT_APP_X_APPLICATION_ID_HEADER as string,
        process.env.REACT_APP_X_APPLICATION_ID as string
    );

    options.user = {
        authenticated: true,
        token: process.env.REACT_APP_X_TOKEN as string,
    };

    options.headers = headers;

    console.log("After setting headers:", options); // Добавим этот console.log

    return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(
    process.env.REACT_APP_X_BACKEND_URL as string,
    httpClient
);

const customDataProvider = {
    ...dataProvider,
    getMany: async (resource: string, params: GetManyParams) => {
        if (resource === "user") {
            const query = params.ids.map((id) => `id=${id}`).join("&");
            const url = `${process.env.REACT_APP_X_BACKEND_URL}/user?${query}`;
            const { json } = await fetchUtils.fetchJson(url);
            return { data: json };
        }
        return dataProvider.getMany(resource, params);
    },
};

const Panel = () => (
    <Admin layout={panelLayout} basename="/panel" dataProvider={dataProvider}>
        <Resource name="users-admin" {...userResource} />
        <Resource name="dates-admin" {...dateResource} />
        <Resource name="places-admin" {...placeResource} />
        <Resource name="date-requests-admin" {...invitesResource} />
        <Resource name="likes-admin" {...likesResource} />
        <Resource name="reports-admin" {...reportResource} />
        <Resource name="payments-admin" {...paymentResource} />
        <Resource name="purchases-admin" {...purchaseResource} />
        <Resource
            name="release-locations-admin"
            {...releaseLocationsResource}
        />
        <Resource name="referal-admin" {...referalResource} />
    </Admin>
);

export { Panel };
